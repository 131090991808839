import { useEffect, useState } from "react"

import { Outlet } from "react-router-dom"

import { Sidebar, Navbar, Footer } from "@pamf-my/ui"

interface Props {
  getUsername?: () => Promise<string | undefined>
  onLogout?: () => void
  auth?: boolean
  brandLogo: string
  pages: {
    id: string
    active?: boolean
    onClick?: () => void
    label: string
    icon: string
    iconOnly?: boolean
    count?: (value: any) => Promise<number>
    hide?: () => Promise<boolean>
  }[]
  buttons: {
    id: string
    onClick?: () => void
    label: string
    icon: string
  }[]
  menus: {
    id: string
    route: string
    onClick?: () => void
    label: string
    icon: string
  }[]
}

const Layout = (props: Props) => {
  const { getUsername, auth, brandLogo, pages, buttons, menus, onLogout } =
    props
  const [open, setOpen] = useState(false)
  const [username, setUsername] = useState<string | undefined>()

  useEffect(() => {
    getUsername?.().then(setUsername)
  })

  if (auth) {
    return (
      <div className="flex flex-col h-screen justify-between">
        <Outlet />
        <Footer />
      </div>
    )
  } else {
    return (
      <div>
        <Sidebar
          open={open}
          brandLogo={brandLogo}
          pages={pages}
          onClose={() => setOpen(false)}
          buttons={buttons}
        />
        <main className="lg:ml-72">
          <Navbar
            username={username}
            switchOpen={() => setOpen(o => !o)}
            isOpen={open}
            onLogout={onLogout}
            pages={pages}
            menus={menus}
          />
          <Outlet />
        </main>
      </div>
    )
  }
}

export default Layout
