import React from "react"

interface Props {
  mini?: boolean
}

const Footer = (props: Props) => {
  const { mini } = props

  return (
    <div
      className={
        "mt-auto underline-offset-2 py-1 bg-neutral text-black" +
        (mini ? "text-[10px]" : "text-sm")
      }
    >
      {/* <div
        className={
          "flex gap-1 py-1 text-center md:text-start justify-between " +
          (mini ? "flex-col px-2" : "flex-row px-6")
        }
      > */}
      <div
        className={
          "flex gap-1 py-1 md:text-start justify-center md:flex-row flex-col px-2"
        }
      >
        <div className={"flex items-center justify-center gap-1"}>
          <a
            className="hover:underline"
            href="https://www.pamf.mg/politique-de-confidentialite"
            target="_blank"
          >
            Politique de protéction de données
          </a>
          |{/* <i className="w-1 h-1 bg-black rounded-full" /> */}
          <a
            className="hover:underline"
            href="https://www.pamf.mg/mentions-legales/"
            target="_blank"
          >
            Mentions légales
          </a>
        </div>

        {/* <span className="text-center">
          PAMF © 2023 - Designed by{" "}
          <a
            href="https://codeandscale.com/fr/"
            target="_blank"
            rel="noopener noreferrer"
            className="underline hover:decoration-2"
          >
            Code & Scale
          </a>
        </span> */}
      </div>
    </div>
  )
}

export default Footer
