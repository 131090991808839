import { useState } from "react"
import { toast } from "react-hot-toast"
import { useFormik } from "formik"

import type { IOfficeFull } from "@pamf-my/types"

import SDeleteOffice from "@/services/office/delete"

interface Props {
  toDelete?: IOfficeFull
  onClose: (created: boolean) => void
}

const DeleteOfficeForm = (props: Props) => {
  const { toDelete, onClose } = props
  const [loading, setLoading] = useState(false)

  const deleteOfficeText = {
    success: "Agence supprimé avec succès",
    error: "Une erreur s'est produite",
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit() {
      if (toDelete) {
        SDeleteOffice({ id: toDelete.id })
          .then(res => {
            if (res.success) {
              toast.success(deleteOfficeText.success)
            } else {
              toast.error(deleteOfficeText.error)
            }
            onClose(true)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
  })

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-3 p-6 mx-auto">
        <div className="flex flex-col gap-3 p-6 mb-3 border border-neutral rounded-md">
          <p>Supprimer définitivement l'agence {toDelete?.name} ?</p>
        </div>
      </div>
      <div className="bg-surface px-6 -mb-5">
        <div className="flex gap-4 justify-end mt-8 py-4">
          <button
            type="button"
            className="bg-surface hover:bg-neutral rounded-full  px-4 py-2"
            onClick={() => onClose(false)}
          >
            Annuler
          </button>
          <button className="bg-primary disabled:opacity-50 hover:disabled:opacity-50 hover:opacity-70 rounded-full text-white px-4 py-2">
            {loading ? "Chargement..." : "Supprimer"}
          </button>
        </div>
      </div>
    </form>
  )
}

export default DeleteOfficeForm
