import JSEncrypt from "jsencrypt"

import * as Sentry from "@sentry/react"

import axios from "axios"

const requests = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
  withCredentials: true,
})

const jsencrypt = new JSEncrypt()
jsencrypt.setPublicKey(import.meta.env.VITE_PUBLIC_KEY)

requests.interceptors.request.use(
  request => {
    if (request.data) {
      request.data = {
        encrypteddata: jsencrypt.encrypt(JSON.stringify(request.data)),
        unencrypteddata: import.meta.env.DEV ? request.data : undefined,
      }
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

requests.interceptors.response.use(
  response => {
    return response
  },
  error => {
    Sentry.captureException(error)

    if (error.response && error.response.status === 401) {
      if (!window.location.href.endsWith("/auth/login")) {
        requests.post<Response>("/logout", {}).finally(() => {
          const token = localStorage.getItem("token")
          if (token) {
            localStorage.setItem("expired-session", "true")
            localStorage.removeItem("token")
          }
          window.location.href = "/auth/login"
        })
      }
    }
    return Promise.reject(error)
  }
)

export default requests
