import React, { ChangeEventHandler, useEffect, useState } from "react"

interface Props {
  id: string
  label?: string
  value: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  placeholders?: string[]
  error?: string
}

const TextInput = (props: Props) => {
  const { id, label, value, onChange, placeholders, error } = props

  const [placeholder, setPlaceholder] = useState("")

  useEffect(() => {
    setPlaceholder(
      placeholders
        ? placeholders[Math.floor(Math.random() * placeholders.length)]
        : ""
    )
  }, [])

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <label
          htmlFor={id}
          className="block mb-0 text-sm font-medium leading-6"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <input
          type="text"
          placeholder={placeholder}
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          className={
            "block w-full rounded-md border-0 py-1.5 px-2 text-black ring-1 ring-inset placeholder:text-muted sm:text-sm focus:ring-2 focus:ring-inset " +
            (error
              ? "ring-danger focus:ring-danger"
              : "ring-neutral focus:ring-primary")
          }
        />
      </div>
      <p className="text-xs leading-5 text-danger">{error}</p>
    </div>
  )
}

export default TextInput
