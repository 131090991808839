import type { AxiosError } from "axios"
import requests from "@/services/requests"
import { TResponse } from "@pamf-my/types"

interface Body {
  username: string
  email: string
  password: string
}

type moderatorCreateResponse = TResponse<undefined>

const SCreateModerator = async (body: Body) => {
  const token = localStorage.getItem("token")

  try {
    const { data } = await requests.post<moderatorCreateResponse>(
      "/user/moderator/create",
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    )
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as moderatorCreateResponse
  }
}

export default SCreateModerator
