import type { AxiosError } from "axios"

import { TResponse } from "@pamf-my/types"

import requests from "@/services/requests"

interface Body {
  username: string
  role: "admin" | "moderator"
}

type moderatorChangeRoleResponse = TResponse<undefined>

const SChangeRole = async (body: Body) => {
  const token = localStorage.getItem("token")

  try {
    const { data } = await requests.post<moderatorChangeRoleResponse>(
      "/user/moderator/change-role",
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    )
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as moderatorChangeRoleResponse
  }
}

export default SChangeRole
