import { useState } from "react"

import { CFab, Container, Modal } from "@pamf-my/ui"

import AdminTable from "@/pages/admins/table"
import { AddAdminInfoForm } from "./add-admin-form"

const AdminsPage = () => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Container>
      <div className="flex flex-col gap-1">
        <AdminTable />
        <CFab label="Ajouter un admin" onClick={() => setModalOpen(true)} />
      </div>
      <Modal
        title="Ajouter un admin"
        small
        setOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <AddAdminInfoForm onClose={() => setModalOpen(false)} />
      </Modal>
    </Container>
  )
}

export default AdminsPage
