import React from "react"

import MenuItem from "./menu-item"
import Icon from "@mdi/react"

interface Props {
  open?: boolean
  brandLogo: string
  onClose: () => void
  pages: {
    id: string
    active?: boolean
    onClick?: () => void
    label: string
    icon: string
    iconOnly?: boolean
    count?: (value: any) => Promise<number>
    hide?: () => Promise<boolean>
    subPage?: () => Promise<
      {
        id: string
        active: boolean
        onClick?: () => void
        label: string
      }[]
    >
  }[]
  buttons: {
    id: string
    onClick?: () => void
    label: string
    icon: string
  }[]
}

const Sidebar = (props: Props) => {
  const { open, pages, buttons, brandLogo, onClose } = props

  return (
    <nav
      className={`${
        open ? "translate-x-0" : "-translate-x-full"
      } flex flex-col justify-between lg:!translate-x-0 transition-transform fixed bg-white h-screen z-10 w-full lg:w-72 border-r border-r-neutral`}
    >
      <div>
        <div className="border-b border-b-neutral grayscale hover:grayscale-0">
          <img
            src={brandLogo}
            className="py-2 lg:py-4 px-3 opacity-0 lg:opacity-100"
            alt=""
          />
        </div>
        <div className="flex flex-col py-2">
          {pages.map(
            ({ id, icon, label, active, count, onClick, hide, subPage }) => (
              <MenuItem
                key={id}
                active={active}
                icon={icon}
                count={count}
                subPage={subPage}
                hide={hide}
                onClick={() => {
                  onClick?.()
                  onClose()
                }}
              >
                {label}
              </MenuItem>
            )
          )}
        </div>
      </div>
      <div className="flex flex-col py-2">
        {buttons.map(({ id, onClick, label, icon }) => (
          <button
            key={id}
            className={
              "flex items-center bg-neutral hover:bg-primary hover:text-white group gap-3 px-3 py-2 mx-1 text-sm text-left rounded"
            }
            onClick={onClick}
          >
            <Icon path={icon} size={1} />
            {label}
          </button>
        ))}
      </div>
    </nav>
  )
}

export default Sidebar
