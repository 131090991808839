import { TextInput } from "@pamf-my/ui"
import { Table } from "@tanstack/react-table"
import { useEffect, useState } from "react"

interface Props<T> {
  table: Table<T>
  filterOn?: string
  dateField?: string
}

const TableHeader = <T,>(props: Props<T>) => {
  const { table, filterOn, dateField } = props
  const [searchQuery, setSearchQuery] = useState("")
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  useEffect(() => {
    if (dateField && startDate && endDate) {
      table.getColumn(dateField)?.setFilterValue({ startDate, endDate })
    }
  }, [dateField, startDate, endDate])

  useEffect(() => {
    if (filterOn) {
      table.getColumn(filterOn)?.setFilterValue(searchQuery)
    }
  }, [searchQuery])

  const { pageSize } = table.getState().pagination

  return (
    <div className="md:px-4 md:py-6">
      <div className="flex flex-wrap justify-between gap-6 mb-6 ">
        {dateField ? (
          <div className="flex gap-6">
            <div className="flex flex-col gap-2 md:flex-row md:items-center">
              Du
              <input
                type="date"
                className="w-full px-2 py-3 text-sm border-0 rounded md:bg-black md:bg-opacity-5 md:py-2 md:w-60"
                onChange={event =>
                  setStartDate(event.currentTarget.valueAsDate)
                }
              />
            </div>
            <div className="flex flex-col gap-2 md:flex-row md:items-center">
              Au
              <input
                type="date"
                className="w-full px-2 py-3 text-sm border-0 rounded md:bg-black md:bg-opacity-5 md:py-2 md:w-60"
                onChange={event => setEndDate(event.currentTarget.valueAsDate)}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex items-center justify-between">
        <div className="items-center hidden gap-1 text-sm md:flex">
          <p>Afficher</p>
          <input
            type="number"
            value={pageSize}
            className="py-1 pl-2 border rounded border-neutral w-14"
            onChange={event => table.setPageSize(+event.currentTarget.value)}
          />
          <p>{pageSize > 1 ? "entrées" : "entrée"}</p>
        </div>
        <TextInput
          id="query"
          value={searchQuery}
          onChange={event => setSearchQuery(event.target.value)}
          placeholders={["Recherche"]}
        />
      </div>
    </div>
  )
}

export default TableHeader
