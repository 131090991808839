import type { AxiosError } from "axios"

import { ILoginFailedMy, ILoginSuccessMy, TResponse } from "@pamf-my/types"

import requests from "@/services/requests"

interface Body {
  username: string
  password: string
}

export type Response = TResponse<ILoginSuccessMy, ILoginFailedMy>

const SLogin = async (body: Body, signal?: AbortSignal): Promise<Response> => {
  try {
    const { data } = await requests.post<Response>("/login", body, {
      signal,
    })
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as Response
  }
}

export default SLogin
