import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useFormik } from "formik"
import * as Yup from "yup"

import SAssignOffice from "@/services/clients/assign-office"
import SOffices from "@/services/list/office"

import type { IClient, IOfficeFull } from "@pamf-my/types"

interface Props {
  userInfo: IClient
  onClose: (updated: boolean) => void
}

export const AssignOfficeForm = (props: Props) => {
  const { userInfo, onClose } = props
  const [loading, setLoading] = useState(false)

  const assignOfficeText = {
    success: "Agence assignée avec succès",
    error: "Une erreur s'est produite",
  }

  const [officeList, setOfficeList] = useState<IOfficeFull[]>([])

  const formik = useFormik({
    initialValues: {
      office: "",
    },
    validationSchema: Yup.object().shape({
      office: Yup.string().required("Champs requis"),
    }),
    onSubmit: values => {
      setLoading(true)
      SAssignOffice({
        alias: userInfo.username,
        officeId: +values.office,
      })
        .then(res => {
          if (res.success) {
            toast.success(assignOfficeText.success)
          } else {
            toast.error(assignOfficeText.error)
          }
        })
        .finally(() => {
          onClose(true)
          setLoading(false)
        })
    },
  })

  useEffect(() => {
    SOffices().then(res => {
      if (res.success) {
        setOfficeList(res.data)
      } else {
        console.error(res)
      }
    })
    // TODO: show the selected office on select by default
  }, [])

  return (
    <form className="" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-2 px-6">
        {/*<div className="font-bold">Changer de mot de passe</div>*/}
        <span>
          Choisissez l'Agence de
          <span className="font-bold"> {userInfo.username}</span>
        </span>
        <br />
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center md:gap-6 gap-1">
          <label htmlFor="password" className="">
            Nouvelle agence
          </label>
          <div className="flex flex-col gap-2">
            <select
              name="office"
              className="md:w-96 form-input px-4 py-3 rounded  border-neutral bg-surface"
              value={formik.values.office}
              onChange={formik.handleChange}
            >
              <option value="">-- Choisissez une agence --</option>
              {officeList.map(office => (
                <option key={office.id} value={office.id}>
                  {office.name}
                </option>
              ))}
            </select>
            <p className="text-danger text-xs">{formik.errors.office}</p>
          </div>
        </div>
      </div>
      <div className="bg-surface px-6 -mb-5">
        <div className="flex gap-4 justify-end mt-8 py-4">
          <button
            type="button"
            className="bg-surface hover:bg-neutral rounded-full  px-4 py-2"
            onClick={() => onClose(false)}
          >
            Annuler
          </button>
          <button
            className="bg-primary disabled:opacity-50 hover:disabled:opacity-50 hover:opacity-70 rounded-full text-white px-4 py-2"
            disabled={formik.errors.office != undefined}
            type="submit"
          >
            {loading ? "Chargement..." : "Terminer"}
          </button>
        </div>
      </div>
    </form>
  )
}
