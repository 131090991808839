import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom/client"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { Icon } from "@mdi/react"
import { mdiLoading } from "@mdi/js"
import * as Sentry from "@sentry/react"

import Navigation from "./navigation"
import "./index.css"

Sentry.init({
  dsn: ["staging", "production"].includes(import.meta.env.MODE)
    ? import.meta.env.VITE_SENTRY_DSN_ADMIN
    : undefined,
  environment: import.meta.env.MODE,
  release: import.meta.env.VITE_RELEASE,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: true,
      maskAllInputs: true,
    }),
  ],
  tracesSampleRate: 1.0,
  sendDefaultPii: true,
})

const App = Sentry.withProfiler(() => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setReady(true)
    }, 1000)
  }, [])

  return (
    <>
      <Toaster position="top-center" />
      <Navigation />
      {!ready ? (
        <div className="relative z-50">
          <div className="fixed inset-0 bg-white" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <div className="flex flex-col gap-2 items-center justify-center mx-auto">
              <div className="bg-neutral rounded-full">
                <Icon path={mdiLoading} size={2} className="animate-spin" />
              </div>
              <p className="text-lg">Connexion en cours...</p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
})

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
