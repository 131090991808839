import { useState } from "react"
import { flexRender } from "@tanstack/react-table"
import type { Table } from "@tanstack/react-table"
import { Icon } from "@mdi/react"
import { mdiMenuDown, mdiMenuUp } from "@mdi/js"

import Pagination from "./pagination"
import TableHeader from "./header"

interface Props<T> {
  table: Table<T>
  filterOn?: string
  dateField?: string
  tableLoading: boolean
}

const Table = <T,>(props: Props<T>) => {
  const { table, filterOn, dateField, tableLoading } = props
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <div className="bg-white rounded hidden md:block w-full p-6">
        <TableHeader table={table} filterOn={filterOn} dateField={dateField} />
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr
                key={headerGroup.id}
                className="h-12 even:bg-primary-light border-b-neutral border-b"
              >
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="px-4 text-left"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center gap-2">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      {{
                        asc: (
                          <div className="bg-neutral rounded">
                            <Icon
                              path={mdiMenuUp}
                              size={1}
                              className="-m-0.5"
                            />
                          </div>
                        ),
                        desc: (
                          <div className="bg-neutral rounded">
                            <Icon
                              path={mdiMenuDown}
                              size={1}
                              className="-m-0.5"
                            />
                          </div>
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className="h-12 even:bg-primary-light">
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className="px-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination table={table} tableLoading={tableLoading} />
      </div>

      <div className="flex flex-col gap-2 md:hidden w-5/6 mx-auto">
        <TableHeader table={table} filterOn={filterOn} />

        {table.getPrePaginationRowModel().rows.map(row => (
          <div key={row.id} className=" flex flex-col gap-2 bg-white px-4 py-3">
            {row.getVisibleCells().map(cell => {
              return (
                <div key={cell.id} className="">
                  {false ? (
                    <h3 className="mb-0 font-bold text-xs">
                      {cell.column.columnDef.header?.toString()}
                    </h3>
                  ) : null}
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </>
  )
}

export default Table
