import { Navigate, Outlet } from "react-router-dom"

import { toast } from "react-hot-toast"
import { useEffect, useState } from "react"

interface Props {
  next: string
  children?: JSX.Element
}

const ProtectedRoute = (props: Props) => {
  const { children, next } = props

  if (true) {
    return children || <Outlet />
  } else {
    return <Navigate to={`/auth/login?next=${next}`} replace />
  }
}

export default ProtectedRoute
