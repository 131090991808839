import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"
import { shallow } from "zustand/shallow"

import brandLight from "@/assets/images/logo-long-light.svg"
import SModerators from "@/services/list/moderator"
import { useAdminStore } from "@/store/is-admin"
import SProfile from "@/services/auth/profile"
import SOffices from "@/services/list/office"
import SUsers from "@/services/list/client"
import AccountsPage from "@/pages/accounts"
import AdminsPage from "@/pages/admins"
import OfficePage from "@/pages/office"
import Login from "@/pages/auth/login"
import ProtectedRoute from "./protected-route"

import SLogout from "@/services/auth/logout"
import Layout from "@/components/layout"
import {
  mdiAccountOutline,
  mdiAccountStarOutline,
  mdiOfficeBuildingOutline,
} from "@mdi/js"

interface Button {
  id: string
  onClick?: () => void
  label: string
  icon: string
}

interface Menu {
  id: string
  route: string
  onClick?: () => void
  label: string
  icon: string
}

const LayoutWrapper = ({ auth }: { auth?: boolean }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const getUsername = async () => {
    const res = await SProfile()

    if (res.success) {
      return res.data.username
    } else {
      console.error(res.data)
    }
  }

  const buttons: Button[] = []

  const menus: Menu[] = []

  const pages = [
    {
      id: "accounts",
      active: location.pathname === "/",
      onClick: () => navigate("/"),
      label: "Clients",
      icon: mdiAccountOutline,
      count: async () => {
        let last = false
        let page = 0
        let result = 0

        while (!last) {
          const users = await SUsers(page)
          if (users.success) {
            result += users.data.length
            last = users.pagination.last
            page++
          }
        }
        return result
      },
    },
    {
      id: "admins",
      active: location.pathname === "/admins",
      onClick: () => navigate("/admins"),
      label: "Admins",
      icon: mdiAccountStarOutline,
      count: async () => {
        const profile = await SProfile()

        if (profile.success) {
          if (profile.data.role === "admin") {
            let last = false
            let page = 0
            let result = 0

            while (!last) {
              const moderators = await SModerators(page)
              if (moderators.success) {
                result += moderators.data.length
                last = moderators.pagination.last
                page++
              }
            }
            return result
          }
        }
        return 0
      },
      hide: async () => {
        const result = await SProfile()
        if (result.success) {
          return result.data.role !== "admin"
        }
        return true
      },
    },
    {
      id: "office",
      active: location.pathname === "/office",
      onClick: () => navigate("/office"),
      label: "Agences",
      icon: mdiOfficeBuildingOutline,
      count: async () => {
        const result = await SOffices()
        if (result.success) {
          return result.data.length
        }

        return 0
      },
    },
  ]

  return (
    <Layout
      auth={auth}
      brandLogo={brandLight}
      onLogout={() => {
        if (!auth) {
          SLogout().then(() => {
            navigate("/auth/login")
          })
        }
      }}
      getUsername={auth ? undefined : getUsername}
      pages={pages}
      buttons={buttons}
      menus={menus}
    />
  )
}

const Navigation = () => {
  const [isAdmin, setIsAdmin] = useAdminStore(
    state => [state.value, state.switchValue],
    shallow
  )

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/" element={<LayoutWrapper auth />}>
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="/" element={<LayoutWrapper />}>
          <Route element={<ProtectedRoute next="/" />}>
            <Route index element={<AccountsPage />} />
            <Route path="admins" element={isAdmin ? <AdminsPage /> : null} />
            <Route path="office" element={<OfficePage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Navigation
