import type { AxiosError } from "axios"
import requests from "@/services/requests"
import { IModerator, TResponse } from "@pamf-my/types"

type moderatorDeleteResponse = TResponse<undefined>

const SDeleteModerator = async (userInfo: IModerator) => {
  const token = localStorage.getItem("token")

  try {
    const { data } = await requests.post<moderatorDeleteResponse>(
      `/user/moderator/delete`,
      { id: userInfo.id },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    )
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as moderatorDeleteResponse
  }
}

export default SDeleteModerator
