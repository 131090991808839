import { Menu, Transition } from "@headlessui/react"
import { Icon } from "@mdi/react"
import { useNavigate } from "react-router-dom"

interface Props {
  children: React.ReactNode
  items: {
    id: string
    route?: string
    onClick?: () => void
    label: string
    icon: string
  }[]
}

const MenuDropdown = (props: Props) => {
  const { children, items } = props
  const navigate = useNavigate()

  return (
    <Menu as="div" className="z-20">
      <Menu.Button className="flex focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <span>{children}</span>
      </Menu.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute mt-2 w-56 right-0 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5">
          <div className="flex flex-col p-1">
            {items.map(({ id, route, label, onClick, icon }) => (
              <Menu.Item key={id}>
                {({ active }) => (
                  <button
                    className="flex w-full rounded-md text-sm items-center bg-white hover:bg-primary hover:text-white px-3 py-2 text-left"
                    onClick={() => {
                      onClick?.()
                      if (route != undefined) {
                        navigate(route)
                      }
                    }}
                  >
                    <Icon path={icon} size={0.8} className="mr-2" />
                    {label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default MenuDropdown
