import React from "react"

const LoadSpin = () => {
  return (
    <div className="w-8 h-8 bg-white rounded-full flex items-center ">
      <div className="animate-spin border-4 rounded-full border-t-primary border-r-white border-b-primary border-l-primary w-6 h-6 mx-auto"></div>
    </div>
  )
}

export default LoadSpin
