import React from "react"

interface Props {
  onClick: () => void
  label: string
}

const Fab = (props: Props) => {
  const { onClick, label } = props

  return (
    <button
      className="flex gap-3 items-center bg-primary px-4 py-2 rounded-3xl transition-all text-white hover:rounded-xl hover:shadow-md"
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default Fab
