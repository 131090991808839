import React, { useEffect, useState } from "react"
import { Icon } from "@mdi/react"

interface Props {
  children: React.ReactNode
  active?: boolean
  onClick?: () => void
  isSub?: boolean
  icon?: string
  count?: (value?: any) => Promise<number>
  hide?: () => Promise<boolean>
  subPage?: () => Promise<
    {
      id: string
      active: boolean
      onClick?: () => void
      label: string
    }[]
  >
}

const MenuItem = (props: Props) => {
  const { children, active, onClick, icon, count, hide, isSub, subPage } = props

  const [subpages, setSubpages] = useState<
    {
      id: string
      active?: boolean
      onClick?: () => void
      label: string
      iconOnly?: boolean
      count?: (value: any) => Promise<number>
      hide?: () => Promise<boolean>
    }[]
  >([])
  const [counted, setCounted] = useState(0)
  const [hided, setHided] = useState(true)

  useEffect(() => {
    if (count) {
      count().then(setCounted)
    }
  }, [count])

  useEffect(() => {
    if (subPage) {
      subPage().then(setSubpages)
    }
  }, [subPage])

  useEffect(() => {
    if (hide) {
      hide().then(setHided)
    } else {
      setHided(false)
    }
  }, [hide])

  if (hided) {
    return null
  }

  return (
    <>
      <button
        className={
          `flex items-center group gap-3 justify-between px-3 py-2 mx-1 text-sm text-left rounded ` +
          (isSub ? "hover:bg-neutral" : "bg-white hover:bg-[#DDDDDD]")
        }
        onClick={onClick}
      >
        {active && (
          <i className="w-1 h-4 transition-all group-hover:h-7 group-hover:w-0.5 bg-primary absolute rounded left-2"></i>
        )}
        <div className="flex items-center gap-3">
          {icon ? <Icon path={icon} size={1} /> : null}
          {isSub ? (
            <div className="relative w-6 h-6">
              <span className="absolute -z-10 h-14 w-5 mr-1 rounded-bl-lg border-[#d3d3d3] border-b-[3px] border-l-[3px] translate-x-2 -translate-y-[42px]"></span>
            </div>
          ) : null}
          {children}
        </div>
        {counted > 0 && (
          <span className="text-xs bg-neutral text-black rounded-full px-3 py-1">
            {counted}
          </span>
        )}
      </button>
      {subpages.length > 0 ? (
        <div className="flex flex-col">
          {subpages.map(subpage => (
            <MenuItem
              isSub
              key={subpage.id}
              active={subpage.active}
              count={subpage.count}
              hide={() =>
                new Promise(resolve => {
                  resolve(subpage.onClick === undefined)
                })
              }
              onClick={() => {
                subpage.onClick?.()
              }}
            >
              {subpage.label}
            </MenuItem>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default MenuItem
