import { Table } from "@tanstack/react-table"

import { LoadSpin } from "@pamf-my/ui"

const getPageSlice = (arr: number[], current: number) => {
  if (arr.length < 3) return arr

  let start = current - 2
  let end = current + 1
  if (start < 0) {
    start = 0
    end = start + 3
  }
  if (end > arr.length) {
    end = arr.length
    start = end - 3
  }
  return arr.slice(start, end)
}

interface Props<T> {
  table: Table<T>
  tableLoading: boolean
}
const Pagination = <T,>(props: Props<T>) => {
  const { table, tableLoading } = props

  const { pageSize, pageIndex } = table.getState().pagination
  const allpage = Array.from({ length: table.getPageCount() }, (_, i) => i + 1)

  let displayNumber

  if (
    pageSize * pageIndex + 1 !==
    pageSize * pageIndex + table.getRowModel().rows.length
  ) {
    displayNumber = `Afficher ${pageSize * pageIndex + 1} à ${pageSize * pageIndex + table.getRowModel().rows.length} sur ${table.getPrePaginationRowModel().rows.length} entrées.`
  } else {
    displayNumber = `Afficher ${pageSize * pageIndex + table.getRowModel().rows.length} sur ${table.getPrePaginationRowModel().rows.length} entrées.`
  }

  if (table.getPrePaginationRowModel().rows.length === 0 && tableLoading) {
    displayNumber = <LoadSpin />
  } else if (
    table.getPrePaginationRowModel().rows.length === 0 &&
    !tableLoading
  ) {
    displayNumber = <div>Aucun résultat</div>
  }

  return (
    <div className="flex justify-between items-center px-4 py-6">
      <div className="text-sm">{displayNumber}</div>
      <div className="flex gap-1">
        <button
          className="rounded px-2 py-1 hover:bg-primary hover:text-white disabled:text-neutral hover:disabled:bg-whitey"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Précédent
        </button>
        <div>
          {getPageSlice(allpage, pageIndex + 1).map(slice => (
            <button
              key={slice}
              onClick={() => table.setPageIndex(slice - 1)}
              className={`rounded px-3 py-1 hover:bg-primary-light ${slice === pageIndex + 1 ? "bg-primary text-white hover:!bg-primary hover:!bg-opacity-70" : ""}`}
            >
              {slice}
            </button>
          ))}
        </div>
        <button
          className="rounded px-2 py-1 hover:bg-primary hover:text-white disabled:text-neutral hover:disabled:bg-whitey"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Suivant
        </button>
      </div>
    </div>
  )
}

export default Pagination
