import type { AxiosError } from "axios"

import requests from "@/services/requests"
import { TResponse } from "@pamf-my/types"

type Response = TResponse<string>

const SLogout = async (signal?: AbortSignal): Promise<Response> => {
  try {
    const { data } = await requests.post<Response>(
      "/logout",
      {},
      {
        signal,
      }
    )
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as Response
  }
}

export default SLogout
