import { useEffect, useState } from "react"
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table"
import moment from "moment"
import { Icon } from "@mdi/react"
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFormTextboxPassword,
  mdiShieldCrownOutline,
} from "@mdi/js"

import { Modal } from "@pamf-my/ui"

import { IModerator } from "@pamf-my/types"

import Table from "@/components/table"
import betweenDateFilter from "@/components/table/utils/betweenDateFilter"
import MenuDropdown from "@/components/menu-dropdown"
import SModerators from "@/services/list/moderator"

import { ChangeModeratorPassword } from "./change-moderator-password"
import { DeleteModeratorForm } from "./delete-moderator-form"
import { ChangeModeratorRole } from "./change-moderator-role"

type TableType = IModerator

interface Page {
  id: string
  route?: string
  onClick?: () => void
  label: string
  icon: string
  iconOnly?: boolean
}

const AdminTable = () => {
  const [tableData, setTableData] = useState<TableType[]>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [roleModalOpen, setRoleModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const [userInfo, setUserInfo] = useState<TableType>()

  const moderatorMenu: Page[] = [
    {
      id: "password",
      onClick: () => setPasswordModalOpen(true),
      label: "Changer le Mot de Passe",
      icon: mdiFormTextboxPassword,
    },
    {
      id: "role",
      onClick: () => setRoleModalOpen(true),
      label: "Changer le rôle en Admin",
      icon: mdiShieldCrownOutline,
    },
  ]

  const adminMenu: Page[] = [
    {
      id: "password",
      onClick: () => setPasswordModalOpen(true),
      label: "Changer le Mot de Passe",
      icon: mdiFormTextboxPassword,
    },
    {
      id: "role",
      onClick: () => setRoleModalOpen(true),
      label: "Changer le rôle en Moderateur",
      icon: mdiShieldCrownOutline,
    },
  ]

  const getModerators = async (page = 0) => {
    setTableLoading(true)
    if (page === 0) {
      setTableData([])
    }
    const res = await SModerators(page)

    if (res.success) {
      setTableData(oldData => [...oldData, ...res.data])
      if (!res.pagination.last) {
        getModerators(page + 1)
      }
    } else {
      console.error(res)
    }
    setTableLoading(false)
  }

  useEffect(() => {
    getModerators()
  }, [])

  const table = useReactTable<TableType>({
    data: tableData,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: [
      {
        accessorKey: "username",
        header: "Nom de l'utilisateur",
        cell: info => <span>{info.getValue()}</span>,
      },
      {
        accessorKey: "createdAt",
        header: "Date d'inscription",
        cell: info => (
          <div>
            {info.getValue() ? (
              <span>{moment.utc(info.getValue()).format("DD/MM/YYYY")}</span>
            ) : (
              <span className="text-muted">Inconnue</span>
            )}
          </div>
        ),
        filterFn: betweenDateFilter,
      },
      {
        accessorKey: "email",
        header: "Email",
        cell: info => <span>{info.getValue()} </span>,
      },
      {
        accessorKey: "admin",
        header: "Role",
        cell: info => (
          <div>
            {info.getValue() ? (
              <span className="text-success">Administrateur</span>
            ) : (
              <span className="">Modérateur</span>
            )}
          </div>
        ),
        filterFn: betweenDateFilter,
      },
      {
        accessorKey: "id",
        header: "",
        cell: info => (
          <div className="flex">
            <button
              className="hover:bg-neutral p-2 text-sm rounded-full hover:bg-opacity-70 text-danger"
              onClick={() => {
                setDeleteModalOpen(true)
                setUserInfo(info.row.original)
              }}
            >
              <Icon path={mdiDeleteOutline} size={1} className="text-danger" />
            </button>
            <div
              className="hover:bg-neutral p-2 text-sm rounded-full hover:bg-opacity-70"
              onClick={() => {
                setUserInfo(info.row.original)
              }}
            >
              <MenuDropdown
                items={info.row.original.admin ? adminMenu : moderatorMenu}
              >
                <Icon path={mdiDotsVertical} size={1} />
              </MenuDropdown>
            </div>
          </div>
        ),
      },
    ],
  })

  return (
    <>
      <Table
        table={table}
        filterOn="username"
        dateField="date"
        tableLoading={tableLoading}
      />
      <Modal
        title="Mettre à jour le Mot de Passe"
        setOpen={passwordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
      >
        <ChangeModeratorPassword
          onClose={() => {
            setPasswordModalOpen(false)
          }}
          userInfo={userInfo!}
        />
      </Modal>
      <Modal
        title="Modifier le rôle en administrateur"
        setOpen={roleModalOpen}
        onClose={() => setRoleModalOpen(false)}
      >
        <ChangeModeratorRole
          onClose={updated => {
            setRoleModalOpen(false)
            if (updated) {
              getModerators()
            }
          }}
          userInfo={userInfo!}
        />
      </Modal>
      <Modal
        title="Suppression du compte"
        setOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <DeleteModeratorForm
          onClose={deleted => {
            setDeleteModalOpen(false)
            if (deleted) {
              getModerators()
            }
          }}
          userInfo={userInfo!}
        />
      </Modal>
    </>
  )
}

export default AdminTable
