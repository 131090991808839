import { useEffect, useState } from "react"

import { CFab, Container, Modal } from "@pamf-my/ui"
import type { IOfficeFull } from "@pamf-my/types"

import SOffices from "@/services/list/office"
import AddOfficeForm from "./add-office-form"
import OfficeTable from "./table"

const OfficePage = () => {
  const [modalAddOpen, setModalAddOpen] = useState(false)
  const [tableData, setTableData] = useState<IOfficeFull[]>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  useEffect(() => {
    setTableLoading(true)
    SOffices()
      .then(res => {
        if (res.success) {
          setTableData(res.data)
        } else {
          console.error(res)
        }
      })
      .finally(() => {
        setTableLoading(false)
      })
  }, [])

  return (
    <Container>
      <div className="flex flex-col gap-3">
        <OfficeTable
          tableData={tableData}
          onUpdate={() => {
            SOffices().then(res => {
              if (res.success) {
                setTableData(res.data)
              } else {
                console.error(res)
              }
            })
          }}
          tableLoading={tableLoading}
        />
        <CFab
          label="Ajouter une agence"
          onClick={() => setModalAddOpen(true)}
        />
      </div>

      <Modal
        title="Ajouter une Agence"
        small
        setOpen={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
      >
        <AddOfficeForm
          onClose={created => {
            setModalAddOpen(false)
            if (created) {
              SOffices().then(res => {
                if (res.success) {
                  setTableData(res.data)
                } else {
                  console.error(res)
                }
              })
            }
          }}
        />
      </Modal>
    </Container>
  )
}

export default OfficePage
