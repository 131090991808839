import React, { Fragment } from "react"

import { Dialog, Transition } from "@headlessui/react"
import { mdiClose } from "@mdi/js"
import { Icon } from "@mdi/react"

interface Props {
  title: string
  children: React.ReactNode
  footer?: React.ReactNode
  setOpen?: boolean
  small?: boolean
  onClose: (submit?: boolean) => void
}

const Modal = (props: Props) => {
  const { title, setOpen, onClose, children, small = false } = props

  return (
    <Transition appear show={setOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all sm:rounded-lg ${
                  small ? "max-w-min" : "max-w-fit"
                }`}
              >
                <Dialog.Title
                  as="h3"
                  className="flex justify-between items-center px-7 py-3 text-2xl font-medium leading border-b border-b-neutral"
                >
                  <span className="">{title}</span>
                  <button
                    className="hover:bg-neutral p-1 rounded opacity-60"
                    type="button"
                    onClick={() => onClose()}
                  >
                    <Icon path={mdiClose} />
                  </button>
                </Dialog.Title>

                <div className="py-5">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
