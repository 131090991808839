import { create } from "zustand"

interface AdminState {
  value: boolean
  setValue: (value: boolean) => void
  switchValue: () => void
}

export const useAdminStore = create<AdminState>(set => ({
  value: true,
  setValue: value => set(() => ({ value })),
  switchValue: () => set(state => ({ value: !state.value })),
}))
