import type { AxiosError } from "axios"
import requests from "@/services/requests"
import { TResponse } from "@pamf-my/types"

interface Body {
  id: number
}

type OfficeDeleteResponse = TResponse<undefined>

const SDeleteOffice = async (body: Body) => {
  const token = localStorage.getItem("token")

  try {
    const { data } = await requests.post<OfficeDeleteResponse>(
      `/office/delete`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    )
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as OfficeDeleteResponse
  }
}

export default SDeleteOffice
