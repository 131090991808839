import React from "react"

interface Props {
  className?: string
  children: React.ReactNode
}

const Container = (props: Props) => {
  const { className = "", children } = props

  return (
    <div className={"px-6 flex flex-col gap-6 py-6 " + className}>
      {children}
    </div>
  )
}

export default Container
