import { useEffect, useState } from "react"
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table"
import moment from "moment"
import { Icon } from "@mdi/react"
import { mdiPlus } from "@mdi/js"

import type { IClient, Pagination } from "@pamf-my/types"
import { Modal } from "@pamf-my/ui"

import betweenDateFilter from "@/components/table/utils/betweenDateFilter"
import SUsers from "@/services/list/client"
import { UpdateSubscriberInfoForm } from "./enable-form"
import { AssignOfficeForm } from "./assign-office-form"
import Table from "@/components/table"

type TableType = IClient

const SubscriberTable = () => {
  const [tableData, setTableData] = useState<TableType[]>([])
  const [tablePagination, setTablePagination] = useState<Pagination>()
  const [page, setPage] = useState(0)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [authorizationModalOpen, setAuthorizationModalOpen] = useState(false)
  const [officeModalOpen, setOfficeModalOpen] = useState(false)

  const [userInfo, setUserInfo] = useState<TableType>()

  const switchToggle =
    "w-9 h-5 bg-danger hover:ring rounded-full peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white  after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-primary"

  useEffect(() => {
    setTableLoading(true)
    SUsers(page)
      .then(res => {
        if (res.success) {
          setTableData(res.data)
          setTablePagination(res.pagination)
        } else {
          console.error(res)
        }
      })
      .finally(() => {
        setTableLoading(false)
      })
  }, [page])

  const table = useReactTable<TableType>({
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: [
      {
        accessorKey: "username",
        header: "Nom",
        filterFn: (row, columnId, filterValue) => {
          return (
            row.original.firstName
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
            row.original.lastName
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
            row.original.email
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
            row.original.username
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
            row.original.cin
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
            row.original.phone.toLowerCase().includes(filterValue.toLowerCase())
          )
        },
        cell: info => {
          return (
            <div className="flex flex-col">
              <span className="">{info.getValue()}</span>
              <p className="-mb-0.5 text-muted text-xs">
                {info.row.original.firstName} {info.row.original.lastName}
              </p>
            </div>
          )
        },
      },
      {
        accessorKey: "createdAt",
        header: "Inscription",
        enableHiding: true,
        meta: {
          noTitle: true,
        },
        cell: info => (
          <div>
            {info.getValue() ? (
              <span>{moment.utc(info.getValue()).format("DD/MM/YYYY")}</span>
            ) : (
              <span className="text-muted">Inconnue</span>
            )}
          </div>
        ),
        filterFn: betweenDateFilter,
      },
      {
        accessorKey: "email",
        header: "Info",
        cell: info => (
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <span className="text-sm">Email: </span>
              <p className="-mb-0.5 text-muted text-xs">{info.getValue()}</p>
            </div>
            <div className="flex items-center gap-1">
              <span className="text-sm">Tel: </span>
              <p className="-mb-0.5 text-muted text-xs">
                {info.row.original.phone}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <span className="text-sm">CIN: </span>
              <p className="-mb-0.5 text-muted text-xs">
                {info.row.original.cin}
              </p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "office",
        header: "Agence",
        cell: info => (
          <div
            onClick={() => {
              setOfficeModalOpen(true)
              setUserInfo(info.row.original)
            }}
          >
            {info.getValue() ? (
              <span className="flex items-center p-1 px-2 text-sm rounded-full cursor-pointer text-primary w-fit bg-primary bg-opacity-5 hover:bg-opacity-10">
                {info.getValue().name}
              </span>
            ) : (
              <button
                title="Ajouter"
                className="flex justify-center items-center hover:bg-neutral bg-primary bg-opacity-5 hover:bg-opacity-10 p-1 mx-3.5 text-sm rounded-full"
              >
                <Icon path={mdiPlus} size={1} />
              </button>
            )}
          </div>
        ),
      },
      {
        accessorKey: "authorized",
        header: "Status",
        cell: info => (
          <div className="flex justify-between">
            <span className="lg:block md:hidden sm:block">
              {info.getValue() ? (
                <span className="flex items-center gap-2 p-1 pl-2 pr-3 text-sm rounded-full w-fit text-success bg-success-light">
                  <span className="w-3 h-3 rounded-full bg-success"></span>
                  <div>Active</div>
                </span>
              ) : (
                <span className="flex items-center gap-2 p-1 pl-2 pr-3 text-sm rounded-full w-fit text-danger bg-danger-light">
                  <span className="w-3 h-3 border-2 rounded-full border-danger"></span>
                  <div>Inactive</div>
                </span>
              )}
            </span>
            <span
              className="mt-1"
              onClick={() => {
                setUserInfo(info.row.original)
                setAuthorizationModalOpen(true)
              }}
            >
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={info.getValue()}
                  onChange={() => setAuthorizationModalOpen(true)}
                />
                <div className={switchToggle}></div>
              </label>
            </span>
          </div>
        ),
      },
    ],
  })

  return (
    <>
      <Table table={table} filterOn="username" tableLoading={tableLoading} />
      <Modal
        title="Autorisation"
        setOpen={authorizationModalOpen}
        onClose={() => setAuthorizationModalOpen(false)}
      >
        <UpdateSubscriberInfoForm
          onClose={updated => {
            setAuthorizationModalOpen(false)
            if (updated) {
              SUsers(page).then(res => {
                if (res.success) {
                  setTableData(res.data)
                } else {
                  console.error(res)
                }
              })
            }
          }}
          userInfo={userInfo!}
        />
      </Modal>
      <Modal
        title="Attribution d'agence"
        setOpen={officeModalOpen}
        onClose={() => setOfficeModalOpen(false)}
      >
        <AssignOfficeForm
          onClose={updated => {
            setOfficeModalOpen(false)
            if (updated) {
              SUsers(page).then(res => {
                if (res.success) {
                  setTableData(res.data)
                } else {
                  console.error(res)
                }
              })
            }
          }}
          userInfo={userInfo!}
        />
      </Modal>
    </>
  )
}

export default SubscriberTable
