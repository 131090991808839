/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { AxiosError } from "axios"

import { TResponse } from "@pamf-my/types"

import requests from "@/services/requests"

interface Body {
  name: string
  agentEmail: string
  agentFirstname: string
  agentLastname: string
}

type OfficeUpdateResponse = TResponse<undefined>

const SUpdateOffice = async (id: number, body: Body) => {
  const token = localStorage.getItem("token")

  try {
    const { data } = await requests.post<OfficeUpdateResponse>(
      `/office/update/${id}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    )
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as OfficeUpdateResponse
  }
}

export default SUpdateOffice
