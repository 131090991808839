import { useState } from "react"

import { useFormik } from "formik"
import { toast } from "react-hot-toast"
import * as Yup from "yup"

import { IModerator } from "@pamf-my/types"

import SChangePassword from "@/services/moderator/change-password"

interface Props {
  userInfo: IModerator
  onClose: () => void
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Champs requis")
    .min(8, "Le mot de passe doit comporter au moins 8 caractères")
    .matches(
      /[a-zA-Z]/,
      "Le mot de passe ne doit pas comporter que des chiffres"
    ),
})

export const ChangeModeratorPassword = (props: Props) => {
  const { onClose, userInfo } = props
  const [loading, setLoading] = useState(false)

  const changePasswordText = {
    success: "Mot de passe modifié avec succès",
    error: "Une erreur s'est produite",
  }

  const formik = useFormik<{
    password: string
  }>({
    initialValues: {
      password: "",
    },
    validationSchema,
    onSubmit(values) {
      setLoading(true)
      SChangePassword({
        username: userInfo.username,
        password: values.password,
      })
        .then(res => {
          if (res.success) {
            toast.success(changePasswordText.success)
          } else {
            toast.error(changePasswordText.error)
          }
          onClose()
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <form className="" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-2 px-6">
        {/*<div className="font-bold">Changer de mot de passe</div>*/}
        <span>
          Changer le Mot de Passe de l'utilisateur
          <span className="font-bold"> {userInfo.username}</span>
        </span>
        <br />
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center md:gap-6 gap-1">
          <label htmlFor="password" className="">
            Nouveau mot de passe
          </label>
          <div className="flex flex-col gap-2">
            <input
              name="password"
              type="password"
              className={
                "input " +
                (formik.errors.password === undefined
                  ? "!border-neutral"
                  : "!border-danger")
              }
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <p className="text-danger text-xs">{formik.errors.password}</p>
          </div>
        </div>
      </div>
      <div className="bg-surface px-6 -mb-5">
        <div className="flex gap-4 justify-end mt-8 py-4">
          <button
            type="button"
            className="bg-surface hover:bg-neutral rounded-full  px-4 py-2"
            onClick={onClose}
          >
            Annuler
          </button>
          <button
            className="bg-primary disabled:opacity-50 hover:disabled:opacity-50 hover:opacity-70 rounded-full text-white px-4 py-2"
            disabled={formik.errors.password != undefined}
            type="submit"
          >
            {loading ? "Chargement..." : "Terminer"}
          </button>
        </div>
      </div>
    </form>
  )
}
