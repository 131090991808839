import type { AxiosError } from "axios"

import type { TResponse } from "@pamf-my/types"

import requests from "@/services/requests"

interface Body {
  username: string
  password: string
}

type moderatorPasswordResponse = TResponse<undefined>

const SChangePassword = async (body: Body) => {
  const token = localStorage.getItem("token")

  try {
    const { data } = await requests.post<moderatorPasswordResponse>(
      "/user/moderator/password",
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    )
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as moderatorPasswordResponse
  }
}

export default SChangePassword
