import { useEffect } from "react"

import { Container } from "@pamf-my/ui"

import SubscriberTable from "./table"

const AccountsPage = () => {
  useEffect(() => {}, [])

  return (
    <Container>
      <SubscriberTable />
    </Container>
  )
}

export default AccountsPage
