/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { AxiosError } from "axios"

import { IOfficeFull, TResponse } from "@pamf-my/types"

import requests from "@/services/requests"

type OfficeListResponse = TResponse<IOfficeFull[]>

const SOffices = async () => {
  const token = localStorage.getItem("token")

  try {
    const { data } = await requests.get<OfficeListResponse>(
      "/office/list-full",
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    )
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as OfficeListResponse
  }
}

export default SOffices
