import { useState } from "react"
import { toast } from "react-hot-toast"
import { useFormik } from "formik"
import * as Yup from "yup"

import type { IOfficeFull } from "@pamf-my/types"

import SCreateOffice from "@/services/office/create"
import SUpdateOffice from "@/services/office/update"

interface TableData {
  name: string
  agentEmail: string
  agentFirstname: string
  agentLastname: string
}

interface Props {
  toUpdate?: IOfficeFull
  onClose: (created: boolean) => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Champs requis"),
  agentEmail: Yup.string().email("Email invalide").required("Champs requis"),
  agentFirstname: Yup.string().required("Champs requis"),
  agentLastname: Yup.string().required("Champs requis"),
})

const AddOfficeForm = (props: Props) => {
  const { toUpdate, onClose } = props
  const [loading, setLoading] = useState(false)

  const addOfficeText = {
    success: "Agence ajouté avec succès",
    error: "Une erreur s'est produite",
  }

  const formik = useFormik<TableData>({
    initialValues: {
      name: toUpdate?.name || "",
      agentEmail: toUpdate?.agentEmail || "",
      agentFirstname: toUpdate?.agentFirstname || "",
      agentLastname: toUpdate?.agentLastname || "",
    },
    validationSchema,
    onSubmit(values) {
      setLoading(true)
      if (toUpdate) {
        SUpdateOffice(toUpdate.id, values)
          .then(res => {
            if (res.success) {
              toast.success(addOfficeText.success)
            } else {
              toast.error(addOfficeText.error)
            }
            onClose(true)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        SCreateOffice(values)
          .then(() => {
            onClose(true)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
  })

  return (
    <form className="" autoComplete="off" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-3 p-6 mx-auto">
        <div className="flex flex-col gap-3 p-6 mb-3 border border-neutral rounded-md">
          <h3 className="-mt-9 w-fit px-1 -mx-1 flex-shrink font-bold text-muted bg-white">
            Agence
          </h3>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center md:gap-6 gap-1">
            <label htmlFor="name" className="">
              Nom
            </label>
            <div className="flex flex-col gap-2">
              <input
                name="name"
                type="text"
                className={
                  "input " +
                  (formik.errors.name === undefined
                    ? "border-neutral"
                    : "border-danger")
                }
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <p className="text-danger text-xs">{formik.errors.name}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 p-6 border border-neutral rounded-md">
          <h3 className="-mt-9 w-fit px-1 -mx-1 flex-shrink font-bold text-muted bg-white">
            Résponsable
          </h3>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center md:gap-6 gap-1">
            <label htmlFor="agentFirstname" className="">
              Prénom
            </label>
            <div className="flex flex-col gap-2">
              <input
                name="agentFirstname"
                type="text"
                className={
                  "input " +
                  (formik.errors.agentFirstname === undefined
                    ? "border-neutral"
                    : "border-danger")
                }
                onChange={formik.handleChange}
                value={formik.values.agentFirstname}
              />
              <p className="text-danger text-xs">
                {formik.errors.agentFirstname}
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center md:gap-6 gap-1">
            <label htmlFor="agentLastname" className="">
              Nom
            </label>
            <div className="flex flex-col gap-2">
              <input
                name="agentLastname"
                type="text"
                className={
                  "input " +
                  (formik.errors.agentLastname === undefined
                    ? "border-neutral"
                    : "border-danger")
                }
                onChange={formik.handleChange}
                value={formik.values.agentLastname}
              />
              <p className="text-danger text-xs">
                {formik.errors.agentLastname}
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center md:gap-6 gap-1">
            <label htmlFor="agentEmail" className="">
              Email
            </label>
            <div className="flex flex-col gap-2">
              <input
                name="agentEmail"
                type="email"
                className={
                  "input " +
                  (formik.errors.agentEmail === undefined
                    ? "border-neutral"
                    : "border-danger")
                }
                onChange={formik.handleChange}
                value={formik.values.agentEmail}
              />
              <p className="text-danger text-xs">{formik.errors.agentEmail}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-surface px-6 -mb-5">
        <div className="flex gap-4 justify-end mt-8 py-4">
          <button
            type="button"
            className="bg-surface hover:bg-neutral rounded-full  px-4 py-2"
            onClick={() => onClose(false)}
          >
            Annuler
          </button>
          <button
            className="bg-primary disabled:opacity-50 hover:disabled:opacity-50 hover:opacity-70 rounded-full text-white px-4 py-2"
            disabled={
              formik.errors.name != undefined ||
              formik.errors.agentEmail != undefined ||
              formik.errors.agentFirstname != undefined ||
              formik.errors.agentLastname != undefined
            }
          >
            {loading ? "Chargement..." : "Terminer"}
          </button>
        </div>
      </div>
    </form>
  )
}

export default AddOfficeForm
