import { AxiosError } from "axios"

import { IModerator, TResponsePaginatedMy } from "@pamf-my/types"

import requests from "@/services/requests"

type ModeratorListResponse = TResponsePaginatedMy<IModerator[]>

const SModerators = async (page = 0) => {
  const token = localStorage.getItem("token")

  try {
    const { data } = await requests.get<ModeratorListResponse>(
      "/user/list/moderator",
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
        params: {
          page,
        },
      }
    )
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as ModeratorListResponse
  }
}

export default SModerators
