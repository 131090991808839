import { useState } from "react"
import { toast } from "react-hot-toast"

import { IModerator } from "@pamf-my/types"

import SDeleteModerator from "@/services/moderator/delete"

interface Props {
  userInfo: IModerator
  onClose: (deleted: boolean) => void
}

export const DeleteModeratorForm = (props: Props) => {
  const { userInfo, onClose } = props
  const [loading, setLoading] = useState(false)

  const deleteAdminText = {
    success: "Modérateur supprimé avec succès",
    error: "Une erreur s'est produite",
  }

  const handleDelete = (userInfo: Props["userInfo"]) => {
    setLoading(true)
    SDeleteModerator(userInfo)
      .then(res => {
        if (res.success) {
          toast.success(deleteAdminText.success)
        } else {
          toast.error(deleteAdminText.error)
        }
        onClose(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <form className="">
      <div className="flex flex-col gap-6 px-6">
        <label htmlFor="admin_role" className="">
          Supprimer définitivement ce modérateur?
        </label>
      </div>
      <div className="bg-surface px-6 -mb-5">
        <div className="flex gap-4 justify-end mt-8 py-4">
          <button
            type="button"
            className="bg-surface hover:bg-neutral rounded-full  px-4 py-2"
            onClick={() => onClose(false)}
          >
            Annuler
          </button>
          <button
            type="button"
            className="bg-primary hover:opacity-70 rounded-full text-white px-4 py-2"
            onClick={() => handleDelete(userInfo)}
          >
            {loading ? "Chargement..." : "Supprimer"}
          </button>
        </div>
      </div>
    </form>
  )
}
