/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from "react"
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { Icon } from "@mdi/react"
import { mdiDeleteOutline, mdiPencilOutline } from "@mdi/js"

import { Modal } from "@pamf-my/ui"
import type { IOfficeFull } from "@pamf-my/types"

import Table from "@/components/table"
import DeleteOfficeForm from "./delete-office-form"
import AddOfficeForm from "./add-office-form"

interface Props {
  tableData: IOfficeFull[]
  onUpdate: () => void
  tableLoading: boolean
}

const OfficeTable = (props: Props) => {
  const { tableData, onUpdate, tableLoading } = props

  const [isOpen, setModalOpen] = useState(false)
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false)

  const [toUpdate, setToUpdate] = useState<IOfficeFull>()
  const [toDelete, setToDelete] = useState<IOfficeFull>()

  const table = useReactTable<IOfficeFull>({
    data: tableData,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: [
      {
        accessorKey: "name",
        header: "Nom",
        cell: info => <span>{info.getValue()}</span>,
      },
      {
        accessorKey: "agentEmail",
        header: "Résponsable",
        cell: info => (
          <div className="flex flex-col">
            <p className="-mb-0.5">
              {info.row.original.agentFirstname}{" "}
              {info.row.original.agentLastname}
            </p>
            <span className="text-muted italic text-xs">{info.getValue()}</span>
          </div>
        ),
      },
      {
        accessorKey: "action",
        header: "",
        cell: info => (
          <>
            <button
              title="Modifier"
              className="hover:bg-neutral p-2 text-sm rounded-full hover:bg-opacity-70"
              onClick={() => {
                setToUpdate(info.row.original)
                setModalOpen(true)
              }}
            >
              <Icon path={mdiPencilOutline} size={1} />
            </button>
            <button
              title="Supprimer"
              className="hover:bg-neutral p-2 text-sm rounded-full hover:bg-opacity-70 text-danger"
              onClick={() => {
                setToDelete(info.row.original)
                setModalDeleteOpen(true)
              }}
            >
              <Icon path={mdiDeleteOutline} size={1} />
            </button>
          </>
        ),
      },
    ],
  })

  return (
    <div>
      <Table table={table} filterOn="name" tableLoading={tableLoading} />
      <Modal
        title={`Modifier l'agence ${toUpdate?.name}`}
        setOpen={isOpen}
        small
        onClose={() => {
          setModalOpen(false)
        }}
      >
        <AddOfficeForm
          onClose={created => {
            setModalOpen(false)
            if (created) {
              onUpdate()
            }
          }}
          toUpdate={toUpdate}
        />
      </Modal>
      <Modal
        title="Supprimer une Agence"
        setOpen={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
      >
        <DeleteOfficeForm
          toDelete={toDelete}
          onClose={deleted => {
            setModalDeleteOpen(false)
            if (deleted) {
              onUpdate()
            }
          }}
        />
      </Modal>
    </div>
  )
}

export default OfficeTable
