import React from "react"
import { mdiAccount, mdiMenuDown } from "@mdi/js"
import { Icon } from "@mdi/react"

interface Props {
  username?: string
}

const UserMenu = (props: Props) => {
  const { username } = props

  return (
    <div className="flex items-center bg-neutral rounded-full">
      <div className="w-8 h-8 flex justify-center items-center rounded-full bg-neutral">
        <Icon path={mdiAccount} size={1} className="text-white" />
      </div>
      <div className="hidden lg:flex items-center px-3 gap-2">
        <span className="hidden md:block">{username}</span>
        <Icon path={mdiMenuDown} size={0.8} className="" aria-hidden="true" />
      </div>
    </div>
  )
}

export default UserMenu
