import SCreateModerator from "@/services/moderator/create"
import { useFormik } from "formik"
import { useState } from "react"
import { toast } from "react-hot-toast"
import * as Yup from "yup"

interface Props {
  onClose: () => void
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Champs requis"),
  email: Yup.string().email("Email invalide").required("Champs requis"),
  password: Yup.string()
    .required("Champs requis")
    .min(8, "Le mot de passe doit comporter au moins 8 caractères")
    .matches(
      /[a-zA-Z]/,
      "Le mot de passe ne doit pas comporter que des chiffres"
    ),
})

export const AddAdminInfoForm = (props: Props) => {
  const { onClose } = props
  const [loading, setLoading] = useState(false)

  const addAdminText = {
    success: "Modérateur ajouté avec succès",
    error: "Une erreur s'est produite",
  }

  const formik = useFormik<{
    username: string
    email: string
    password: string
  }>({
    initialValues: {
      username: "",
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit(values) {
      setLoading(true)
      SCreateModerator(values)
        .then(res => {
          if (res.success) {
            toast.success(addAdminText.success)
          } else {
            toast.error(addAdminText.error)
          }
          onClose()
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <form className="" autoComplete="off" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-3 p-6 mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center md:gap-6 gap-1">
          <label htmlFor="username" className="">
            Nom d'utilisateur
          </label>
          <div className="flex flex-col gap-2">
            <input
              name="username"
              type="text"
              className={
                "input " +
                (formik.errors.username === undefined
                  ? "border-neutral"
                  : "border-danger")
              }
              onChange={formik.handleChange}
              value={formik.values.username}
            />
            <p className="text-danger text-xs">{formik.errors.username}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center md:gap-6 gap-1">
          <label htmlFor="email" className="">
            Email
          </label>
          <div className="flex flex-col gap-2">
            <input
              name="email"
              type="email"
              className={
                "input " +
                (formik.errors.email === undefined
                  ? "border-neutral"
                  : "border-danger")
              }
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <p className="text-danger text-xs">{formik.errors.email}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center md:gap-6 gap-1">
          <label htmlFor="password" className="">
            Mot de passe
          </label>
          <div className="flex flex-col gap-2">
            <input
              name="password"
              type="password"
              className={
                "input " +
                (formik.errors.password === undefined
                  ? "!border-neutral"
                  : "!border-danger")
              }
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <p className="text-danger text-xs">{formik.errors.password}</p>
          </div>
        </div>
      </div>
      <div className="bg-surface px-6 -mb-5">
        <div className="flex gap-4 justify-end mt-8 py-4">
          <button
            type="button"
            className="bg-surface hover:bg-neutral rounded-full px-4 py-2"
            onClick={onClose}
          >
            Annuler
          </button>
          <button
            className="bg-primary disabled:opacity-50 hover:disabled:opacity-50 hover:opacity-70 rounded-full text-white px-4 py-2"
            disabled={
              formik.errors.email != undefined ||
              formik.errors.username != undefined ||
              formik.errors.password != undefined
            }
            type="submit"
          >
            {loading ? "Chargement..." : "Terminer"}
          </button>
        </div>
      </div>
    </form>
  )
}
