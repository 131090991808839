import { useState } from "react"
import { toast } from "react-hot-toast"

import type { IClient } from "@pamf-my/types"

import SAuthorize from "@/services/clients/authorize"

interface Props {
  userInfo: IClient
  onClose: (updated: boolean) => void
}

export const UpdateSubscriberInfoForm = (props: Props) => {
  const { userInfo, onClose } = props
  const [loading, setLoading] = useState(false)

  const activationText = {
    enableSuccess: "Compte activé avec succès",
    disableSuccess: "Compte désactivé avec succès",
    error: "Une erreur s'est produite",
  }

  const handleChange = (userInfo: Props["userInfo"]) => {
    setLoading(true)
    SAuthorize({
      alias: userInfo.username,
      authorize: !userInfo.authorized,
    })
      .then(res => {
        if (res.success) {
          userInfo.authorized
            ? toast.success(activationText.disableSuccess)
            : toast.success(activationText.enableSuccess)
        } else {
          toast.error(activationText.error)
        }
        onClose(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <form className="">
      <div className="flex flex-col gap-6 px-6">
        {userInfo.authorized ? (
          <label htmlFor="admin_role" className="">
            Confirmer la Desactivation du compte{" "}
            <span className="font-bold">{userInfo.username}</span>
          </label>
        ) : (
          <label htmlFor="admin_role" className="">
            Confirmer l'Activation du compte{" "}
            <span className="font-bold">{userInfo.username}</span>
          </label>
        )}
      </div>
      <div className="bg-surface px-6 -mb-5">
        <div className="flex gap-4 justify-end mt-8 py-4">
          <button
            type="button"
            className="bg-surface hover:bg-neutral rounded-full  px-4 py-2"
            onClick={() => onClose(false)}
          >
            Annuler
          </button>
          <button
            type="button"
            className="bg-primary hover:opacity-70 rounded-full text-white px-4 py-2"
            onClick={() => handleChange(userInfo)}
          >
            {loading ? "Chargement..." : "Confirmer"}
          </button>
        </div>
      </div>
    </form>
  )
}
