import type { AxiosError } from "axios"
import requests from "@/services/requests"
import { TResponse } from "@pamf-my/types"

interface Body {
  alias: string
  officeId: number
}

type Response = TResponse<undefined>

const SAssignOffice = async (body: Body) => {
  const token = localStorage.getItem("token")

  try {
    const { data } = await requests.post<Response>(
      "/user/assign-office",
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    )

    return data
  } catch (error) {
    return (error as AxiosError).response?.data as Response
  }
}

export default SAssignOffice
