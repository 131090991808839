import { useEffect, useState } from "react"
import { useFormik } from "formik"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-hot-toast"
import { Icon } from "@mdi/react"
import { mdiShieldAccount } from "@mdi/js"

import brand from "@/assets/images/logo-long.svg"
import SLogin from "@/services/auth/login"

import * as Yup from "yup"

const Login = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [searchParams, _setSearchParams] = useSearchParams()

  useEffect(() => {
    if (localStorage.getItem("expired-session") === "true") {
      toast.error("Session expirée, veuillez vous reconnecter")
      localStorage.removeItem("expired-session")
    }
  }, [])

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Champs requis")
      .matches(/[a-zA-Z]/, "Mot de passe invalide"),
  })

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: values => {
      setLoading(true)
      SLogin(values)
        .then(res => {
          if (res.success) {
            navigate(searchParams.get("next") || "/", { replace: true })
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("alias", res.data.alias)
          } else {
            const errorMessage: string | undefined = {
              INVALID_CREDENTIALS: "Alias ou Mot de passe invalide",
              USER_NEED_TO_SUBSCRIBE: "Vous devez d'abord vous inscrire",
              "USER WAIT FOR AUTHORIZATION": "En attente d'autorisation ",
              "Account locked. Please try after few minutes":
                "Compte temporairement indisponible.Veuillez réessayer dans quelques minutes.",
              USER_NEED_TO_REGISTER: "Vous devez d'abord créer un compte",
            }[res.data.message]
            if (errorMessage) {
              toast.error(errorMessage)
            } else {
              toast.error("Une erreur s’est produite")
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <div className="flex flex-col items-center p-4 md:w-[496px] w-96 m-auto py-8">
      <img src={brand} className="mb-20" />
      <div className="bg-white rounded drop-shadow-sm w-full px-4 py-8 relative flex flex-col items-center mb-4">
        <div className="w-24 h-24 bg-primary rounded-full mx-auto flex justify-center items-center absolute -top-12 ">
          <Icon path={mdiShieldAccount} size={3} className="text-white" />
        </div>
        <div className="flex flex-col w-4/5 mx-auto my-8 gap-12">
          <div className="text-3xl text-center">Se connecter</div>
          <form className="flex flex-col gap-6" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="alias">Nom d'utilisateur</label>
              <input
                type="text"
                name="username"
                className="form-input px-4 py-3 rounded border-neutral border bg-surface"
                onChange={formik.handleChange}
                value={formik.values.username}
              />
            </div>

            <div className="flex flex-col  w-full  gap-2">
              <label htmlFor="password">Mot de passe</label>
              <input
                type="password"
                name="password"
                className={
                  "form-input px-4 py-3 rounded border-neutral border bg-surface " +
                  (formik.errors.password === undefined
                    ? "!border-neutral"
                    : "!border-danger")
                }
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <p className="text-danger text-xs">{formik.errors.password}</p>
            </div>
            <button
              className="bg-primary text-white mt-4 py-2 rounded hover:bg-opacity-70 disabled:bg-opacity-50"
              type="submit"
              disabled={loading}
            >
              {loading ? "Connexion en cours..." : "Connexion"}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
