import type { AxiosError } from "axios"

import type { IProfile, TResponse } from "@pamf-my/types"

import requests from "@/services/requests"

type Response = TResponse<IProfile>

const SProfile = async (signal?: AbortSignal): Promise<Response> => {
  const token = localStorage.getItem("token")
  try {
    const response = await requests.get<Response>("/profile", {
      signal,
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    })

    return response.data
  } catch (error) {
    return (error as AxiosError).response?.data as Response
  }
}

export default SProfile
