import type { FilterMeta, Row } from "@tanstack/react-table"
import moment from "moment"

const betweenDateFilter = <TableType>(
  row: Row<TableType>,
  columnId: string,
  value: { startDate: Date; endDate: Date },
  addMeta: (meta: FilterMeta) => void
) => {
  const startUtc = moment.utc(value.startDate)
  const endUtc = moment.utc(value.endDate)
  const currentUtc = moment.utc(row.getValue(columnId))

  return currentUtc.isBetween(startUtc, endUtc, "day", "[]")
}

export default betweenDateFilter
