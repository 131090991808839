import { AxiosError } from "axios"

import type { IClient, TResponsePaginatedMy } from "@pamf-my/types"

import requests from "@/services/requests"

type Response = TResponsePaginatedMy<IClient[]>

const SUser = async (page: number, signal?: AbortSignal): Promise<Response> => {
  const token = localStorage.getItem("token")
  try {
    const { data } = await requests.get<Response>("/user/list/client", {
      signal,
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      params: {
        page,
      },
    })
    return data
  } catch (error) {
    return (error as AxiosError).response?.data as Response
  }
}

export default SUser
