import React from "react"

import Fab from "./fab"

interface Props {
  onClick: () => void
  label: string
}

const CFab = (props: Props) => {
  const { onClick, label } = props

  return (
    <div className="sticky flex justify-end bottom-6 w-full">
      <Fab onClick={onClick} label={label} />
    </div>
  )
}

export default CFab
