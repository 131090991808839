import { useState } from "react"
import { toast } from "react-hot-toast"

import { IModerator } from "@pamf-my/types"

import SChangeRole from "@/services/moderator/change-role"

interface Props {
  userInfo: IModerator
  onClose: (updated: boolean) => void
}

export const ChangeModeratorRole = (props: Props) => {
  const { userInfo, onClose } = props
  const [loading, setLoading] = useState(false)

  const changeRoleText = {
    toAdminSuccess: "Changement en administrateur réussi",
    toModeratorSuccess: "Changement en modérateur réussi",
    error: "Une erreur s'est produite",
  }

  const handleChange = (userInfo: Props["userInfo"]) => {
    setLoading(true)
    SChangeRole({
      username: userInfo.username,
      role: userInfo.admin ? "moderator" : "admin",
    })
      .then(res => {
        if (res.success) {
          userInfo.admin
            ? toast.success(changeRoleText.toModeratorSuccess)
            : toast.success(changeRoleText.toAdminSuccess)
        } else {
          toast.error(changeRoleText.error)
        }
        onClose(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <form className="">
      <div className="flex flex-col gap-6 px-6">
        <label htmlFor="admin_role" className="">
          Confirmer la modification du rôle de<span> {userInfo.username} </span>
          <span>en {userInfo.admin ? "moderateur" : "administrateur"}</span>
        </label>
      </div>
      <div className="bg-surface px-6 -mb-5">
        <div className="flex gap-4 justify-end mt-8 py-4">
          <button
            type="button"
            className="bg-surface hover:bg-neutral rounded-full  px-4 py-2"
            onClick={() => onClose(false)}
          >
            Annuler
          </button>
          <button
            type="button"
            className="bg-primary hover:opacity-70 rounded-full text-white px-4 py-2"
            onClick={() => handleChange(userInfo)}
          >
            {loading ? "Chargement..." : "Confirmer"}
          </button>
        </div>
      </div>
    </form>
  )
}
