import React, { Fragment, useEffect, useState } from "react"
import { mdiArrowLeft, mdiChevronRight, mdiLogout, mdiMenu } from "@mdi/js"
import { Icon } from "@mdi/react"

import MenuDropdown from "../../components/menu-dropdown"
import UserMenu from "./user-menu"

interface Page {
  id: string
  route: string
  onClick?: () => void
  label: string
  icon: string
  iconOnly?: boolean
}

interface Props {
  username?: string
  switchOpen: () => void
  isOpen: boolean
  onLogout?: () => void
  actions?: {
    icon: string
    onClick: () => void
  }[]
  pages: {
    id: string
    active?: boolean
    onClick?: () => void
    label: string
    icon: string
    iconOnly?: boolean
    count?: (value: any) => Promise<number>
    subPage?: () => Promise<
      {
        id: string
        active: boolean
        onClick?: () => void
        label: string
      }[]
    >
  }[]
  menus: {
    id: string
    route: string
    onClick?: () => void
    label: string
    icon: string
  }[]
}

const Navbar = (props: Props) => {
  const { username, switchOpen, actions, pages, onLogout, menus, isOpen } =
    props
  const [scrollPosition, setScrollPosition] = useState(0)
  const [headerLabels, setHeaderLabels] = useState<string[]>([])
  /* const [isOpen, setIsOpen] = useState<boolean>(false) */

  const userMenu: Page[] = [
    ...menus,
    {
      id: "login",
      route: "/auth/login",
      onClick: onLogout,
      label: "Déconnexion",
      icon: mdiLogout,
    },
  ]

  const handleScroll = () => {
    const position = window.scrollY
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    setHeaderLabels([])

    let label = pages.find(page => page.active)?.label
    if (label) {
      setHeaderLabels([label])
    } else {
      pages.forEach(
        page =>
          page.subPage?.().then(subPages => {
            const activePage = subPages.find(page => page.active)

            if (activePage) {
              setHeaderLabels([page.label, activePage.label])
            }
          })
      )
    }
  }, [pages])

  const style = {
    "--tw-bg-opacity": scrollPosition / 20,
    borderBottomWidth: Math.min(Math.max((scrollPosition - 20) / 20, 0), 1),
  }

  return (
    <>
      <nav
        className={"sticky top-0 z-20 bg-white border-b border-b-neutral"}
        style={style}
      >
        <div className="px-3 lg:px-6 flex items-center justify-between h-[66px]">
          <div className="flex gap-2 items-center">
            <button
              className="p-1 hover:bg-neutral lg:hidden rounded"
              onClick={() => {
                switchOpen()
                /* setIsOpen(!isOpen) */
              }}
            >
              {isOpen ? (
                <Icon path={mdiArrowLeft} size={1} />
              ) : (
                <Icon path={mdiMenu} size={1} />
              )}
            </button>
            <h1 className="flex items-center gap-1 font-bold text-lg opacity-70">
              {headerLabels.length === 0 ? (
                <span className="w-72 h-5 bg-neutral animate-pulse rounded-3xl"></span>
              ) : (
                headerLabels.map((headerLabel, index) => (
                  <Fragment key={index}>
                    {index > 0 ? (
                      <Icon
                        path={mdiChevronRight}
                        size={1.2}
                        className="opacity-50"
                      />
                    ) : null}
                    <span>{headerLabel}</span>
                  </Fragment>
                ))
              )}
            </h1>
          </div>
          <div className="flex items-center gap-4">
            {actions?.map((action, index) => (
              <button
                key={index}
                type="button"
                onClick={action.onClick}
                className="p-2 rounded-full hover:bg-neutral"
              >
                <Icon path={action.icon} size={1} />
              </button>
            ))}
            <MenuDropdown items={userMenu}>
              <UserMenu username={username} />
            </MenuDropdown>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
